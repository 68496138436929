import AWS from 'aws-sdk';
import devEnv from './aws-exports-dev';
import prodEnv from './aws-exports';

const API_KEY = process.env.REACT_APP_API_KEY;
const ACCESS_KEY = process.env.REACT_APP_SECRET_KEY;

const isDev = process.env.REACT_APP_ENVIRONMENT === "development";
AWS.config.update({
  region: isDev ? devEnv['aws_project_region'] : prodEnv["aws_project_region"],
  credentials: {
    accessKeyId: API_KEY,
    secretAccessKey: ACCESS_KEY,
  },
});

const docClient = new AWS.DynamoDB.DocumentClient();

export { docClient };

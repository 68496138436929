import { useEffect, FC, ReactElement } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import AuthenticationPage from './pages/AuthenticationPage/AuthenticationPage';
import DashboardWrapper from './pages/DashboardPages/DashboardWrapper';
import prodEnv from './aws-exports';
import devEnv from './aws-exports-dev';
import { Amplify } from 'aws-amplify';
import './App.scss';

// Individual Pages:
import Dashboard from './pages/DashboardPages/Dashboard/Dashboard';
import Merchant from './pages/DashboardPages/Merchant/Merchant';
import Product from './pages/DashboardPages/Product/Product';
import Articles from './pages/DashboardPages/Articles/Articles';
import Categories from './pages/DashboardPages/Categories/Categories';
import Settings from './pages/DashboardPages/Settings/Settings';
import Upload from './fileupload/Uploads';
import Users from './pages/DashboardPages/Users/Users';
import FAQ from './pages/DashboardPages/FAQ/FAQ';
import UsersNew from './pages/DashboardPages/Users/UsersNew';

import './testOne'; //
import BucketList from './pages/DashboardPages/BucketList/BucketList';
import Rewards from './pages/DashboardPages/Rewards/Rewards';
import GoalsPage from './pages/DashboardPages/Goals/GoalsPage';

const env = process.env.REACT_APP_ENVIRONMENT;

if (env === 'production') {
  console.log("PRODUCTION ENV");
  Amplify.configure(prodEnv);
} else {
  console.log("DEV ENV", env);
  Amplify.configure(devEnv);
}

function App() {
  useEffect(() => {
    const saathiAccessToken = sessionStorage.getItem('saathi_accessToken');
    const saathiIdToken = sessionStorage.getItem('saathi_idToken');
    const path = window.location.pathname;
    if ((!saathiAccessToken || !saathiIdToken) && path !== '/') {
      window.location.href = '/';
    }
  }, []);

  const dashboardPages: {
    path: string;
    page: ReactElement;
  }[] = [
    {
      path: 'Dashboard',
      page: <Dashboard />,
    },
    {
      path: 'Merchants',
      page: <Merchant />,
    },
    {
      path: 'Products',
      page: <Product />,
    },
    {
      path: 'Articles',
      page: <Articles />,
    },
    {
      path: 'Categories',
      page: <Categories />,
    },
    {
      path: 'Settings',
      page: <Settings />,
    },
    {
      path: 'Uploads',
      page: <Upload />,
    },
    {
      path: 'Users',
      // page: <Users />,
      page: <UsersNew />,
    },
    {
      path: 'FAQ',
      page: <FAQ />,
    },
    {
      path: 'Bucket List',
      page: <BucketList />,
    },
    {
      path: 'Goals',
      page: <GoalsPage />
    }
  ];

  return (
    <div className="App">
      <Routes>
        {/* Index (Login) page: */}
        <Route path="/" element={<AuthenticationPage />} />

        {/* Once inside the App: */}
        <Route
          path="/*"
          element={
            <DashboardWrapper>
              <Outlet />
            </DashboardWrapper>
          }
        >
          {dashboardPages.map(({ path, page }, i) => (
            <Route path={path} element={page} key={i} />
          ))}
        </Route>
      </Routes>
      <Toaster />
    </div>
  );
}

export default App;

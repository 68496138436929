import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { handleAPICall, checkStatusCode, createToken } from './APIUtils'

const initialState = {
    goalsLoading: false,
    goals: [],
    userIdList: [],
    usersList: [],
    goalsError: "",
    totalGoals: 0,
    valueOfGoals: 0,
    totalSavedAmount: 0,
    totalCustomers: 0,
    createConversionLoading: false,
    createConversionError: "",
    updateConversionLoading: false,
    updateConversionError: "",
    goalsMetric: {}
}

async function queryUserData(id) {
    try {
        const data = await handleAPICall("saathiAPI", `/user/${id}`, {}, "GET");
        if (data.error && Object.keys(data.error).length > 0) {
            return null;
        }
        return data;
    } catch (err) {
        console.error("Error getting item:", err);
        return null;
    }
}

async function getCashKaroConversion(goalId) {
    try {
        const conversionsList = await handleAPICall("merchantApi", `/merchant-dashboard/conversion?goalId=${goalId}`, {}, "GET");
        return conversionsList?.conversionList;
    } catch (error) {
        console.error("Error getting item:", error);
        return null;
    }
}

async function merchantGoalHelperFunc(savedProductsList, references) {
    try {
        await Promise.all(savedProductsList?.savingProducts.map(async (ele) => {
            if (ele && ele.goalId) {
                let t = await handleAPICall("merchantApi", `/merchant-dashboard/goals?goalId=${ele.goalId}`, {}, "GET");
                t = t?.goal;
                if (t && t.goalStatus) {
                    if (t?.userId) {
                        const d = new Date(t?.createdAt).toDateString();

                        references.userIdList.push(t?.userId);
                        references.userIdList = references.userIdList.concat(t?.sharedUsersId || []);
                    }
                    const { netGoalAmount, savedAmount } = t;
                    const c = await getCashKaroConversion(ele.goalId);
                    if (c && c.length > 0) {
                        references.conversionsList.push(c[0])
                        references.filteredGoals.push({ ...t, conversions: c[0] });
                    } else {
                        references.filteredGoals.push({ ...t, conversions: {} });
                    }
                    references.valueOfGoals += netGoalAmount;
                    references.totalSavedAmount += savedAmount;
                }
            }
        }));
    } catch (error) {
        console.error(error);
    }
}

export const listAllMerchantGoalsAPI = createAsyncThunk("goals/merchant", async ({ pageNum = 1, limit = 10, merchantId = "", callback = () => { } }, thunkAPI) => {
    try {
        const merchantData = await handleAPICall("merchantApi", "/merchant?status=true", {}, "GET");
        let references = {
            savedProductsList: [],
            filteredGoals: [],
            valueOfGoals: 0,
            totalSavedAmount: 0,
            userIdList: [],
            conversionsList: [],
        };

        if (merchantData && merchantData.success?.data) {
            await Promise.all(merchantData.success?.data.map(async (ele) => {
                references.savedProductsList = await handleAPICall("merchantApi", `/merchant-dashboard/savings-product?merchantId=${ele?.id}`, {}, "GET");
                await merchantGoalHelperFunc(references.savedProductsList, references);
            }));
        }

        const finalUserIdList = new Set(references.userIdList);
        const totalGoals = references.filteredGoals.length;
        let finalUserList = [];

        await Promise.all(Array.from(finalUserIdList).map(async (ele) => {
            try {
                const u = await queryUserData(ele);
                if (u) {
                    finalUserList.push(u);
                }
            } catch (error) {
                console.log(error);
            }
        }));

        return {
            data: references.filteredGoals,
            totalGoals,
            valueOfGoals: references.valueOfGoals,
            totalSavedAmount: references.totalSavedAmount,
            totalCustomers: finalUserIdList.size,
            userIdList: Array.from(finalUserIdList),
            usersList: finalUserList
        };
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});


export const createGoalConversionStatusAPI = createAsyncThunk("conversion/create", async ({ body, callback = () => { } }, thunkAPI) => {
    try {
        const goalId = body["goalId"];
        const options = {
            body: body
        }
        const res = await handleAPICall("merchantApi", "/merchant/conversion", options, "POST");
        callback(res);
        if (res.error && Object.keys(res.error).length > 0) {
            return thunkAPI.rejectWithValue("Create Goal Conversion Failed");
        }
        return { data: res, goalId: goalId };
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const updateConversionStatusAPI = createAsyncThunk("conversion/update", async ({ body, conversionId, callback = () => { } }, thunkAPI) => {
    try {
        const goalId = body["goalId"];

        const options = {
            body: body
        }
        const res = await handleAPICall("merchantApi", `/merchant/conversion/${conversionId}`, options, "UPDATE");
        callback(res, true);
        if (res.error && Object.keys(res.error).length > 0) {
            return thunkAPI.rejectWithValue("Create Goal Conversion Failed");
        }
        return { data: res, goalId: goalId, conversionId: conversionId };
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const goalsSlice = createSlice({
    name: "Goals",
    initialState,
    reducers: {
        updateGoalsList: (state, action) => {
            state.goals = action.payload;
        },
        updateGoalsMetric: (state, action) => {
            state.goalsMetric = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(listAllMerchantGoalsAPI.pending, (state) => {
            state.goalsLoading = true;
            state.goalsError = ""
        })

        builder.addCase(listAllMerchantGoalsAPI.fulfilled, (state, action) => {
            state.goalsLoading = false;
            state.goals = action.payload.data;
            state.totalGoals = action.payload.totalGoals;
            state.valueOfGoals = action.payload.valueOfGoals;
            state.totalSavedAmount = action.payload.totalSavedAmount;
            state.totalCustomers = action.payload.totalCustomers;
            state.userIdList = action.payload.userIdList;
            state.usersList = action.payload.usersList;
        })

        builder.addCase(listAllMerchantGoalsAPI.rejected, (state, action) => {
            state.goalsLoading = false;
            state.goalsError = action.payload;
        })

        builder.addCase(createGoalConversionStatusAPI.pending, (state) => {
            state.createConversionError = "";
            state.createConversionLoading = true;
        })

        builder.addCase(createGoalConversionStatusAPI.fulfilled, (state, action) => {
            state.createConversionLoading = false;
            let currentGoals = [...state.goals];
            const idx = currentGoals.findIndex(e => e.id === action.payload.goalId);
            if (idx !== -1) {
                currentGoals[idx].conversions = action.payload.data;
            }
        })

        builder.addCase(createGoalConversionStatusAPI.rejected, (state, action) => {
            state.createConversionLoading = false;
            try {
                state.createConversionError = action.payload.error.response.data.error.message
            } catch (error) {
                state.createConversionError = "Create Goal Conversion Failed"
            }
        })

        builder.addCase(updateConversionStatusAPI.pending, (state) => {
            state.updateConversionLoading = true;
            state.updateConversionError = ""
        })
        builder.addCase(updateConversionStatusAPI.fulfilled, (state, action) => {
            state.updateConversionLoading = false;
            let currentGoals = [...state.goals];
            const idx = currentGoals.findIndex(e => e.id === action.payload.goalId);
            if (idx !== -1) {
                currentGoals[idx].conversions = action.payload.data;
            }
        })
        builder.addCase(updateConversionStatusAPI.rejected, (state, action) => {
            state.updateConversionLoading = false;
            try {
                state.updateConversionError = action.payload.error.response.data.error.message
            } catch (error) {
                state.updateConversionError = "Update Goal Conversion Failed"
            }
        })
    }
})
export const { updateGoalsList, updateGoalsMetric } = goalsSlice.actions;
export default goalsSlice.reducer;